import React from 'react';
import styles from './Contato.module.css';
import TituloComponent from '../components/Titulo/TituloComponent';
import { FaWhatsapp } from 'react-icons/fa'; // Importação do ícone de WhatsApp

const Contato = () => {
    const setores = [
        {
            nome: 'Atendimento',
            descricao: 'Dúvidas gerais, matrículas, suporte aos pais e informações sobre a escola.',
            email: 'colegioalternativocolaco@gmail.com',
            whatsapp: 'https://api.whatsapp.com/send/?phone=558534822415&text=Ol%C3%A1%2C+visitei+o+site+do+Col%C3%A9gio+Alternativo+Cola%C3%A7o+e+gostaria+de+tirar+algumas+d%C3%BAvidas+sobre+o+col%C3%A9gio%2C+as+condi%C3%A7%C3%B5es+de+matr%C3%ADcula+e+os+servi%C3%A7os+oferecidos.+Estou+interessado+em+realizar+a+matr%C3%ADcula+e+preciso+de+mais+informa%C3%A7%C3%B5es.+Poderiam+me+ajudar%3F&type=phone_number&app_absent=0',
        },
        {
            nome: 'Diretoria',
            descricao: 'Questões financeiras, matrículas e dúvidas gerais.',
            email: 'colegioalternativocolaco@gmail.com',
            whatsapp: 'https://api.whatsapp.com/send/?phone=5585988068633&text=Ol%C3%A1%2C+encontrei+o+site+do+Col%C3%A9gio+Alternativo+Cola%C3%A7o+e+estou+interessado+em+realizar+uma+matr%C3%ADcula+ou+efetuar+um+pagamento.+Poderiam+me+auxiliar%3F&type=phone_number&app_absent=0',
        },
        {
            nome: 'Secretaria',
            descricao: 'Questões relacionadas a declarações, documentações e dúvidas em geral.',
            email: 'colegioalternativocolaco@gmail.com',
            whatsapp: 'https://api.whatsapp.com/send/?phone=558588987899&text=Ol%C3%A1%2C+encontrei+o+site+do+Col%C3%A9gio+Alternativo+Cola%C3%A7o+e+gostaria+de+solicitar+uma+documenta%C3%A7%C3%A3o+espec%C3%ADfica.+Poderiam+me+informar+como+proceder%3F&type=phone_number&app_absent=0',
        },
    ];

    return (
        <>
            <TituloComponent name="Contato" />
            <div className={styles.contatoContainer}>
                <p className={styles.pageDescription}>
                    Entre em contato com o setor correspondente à sua necessidade. Estamos prontos para atendê-lo!
                </p>

                <div className={styles.sectorsContainer}>
                    {setores.map((setor, index) => (
                        <div key={index} className={styles.setorCard}>
                            <h2 className={styles.setorTitle}>{setor.nome}</h2>
                            <p className={styles.setorDescription}>{setor.descricao}</p>
                            <p className={styles.contactInfo}>
                                <strong>Email:</strong>{' '}
                                <a href={`mailto:${setor.email}`}>{setor.email}</a>
                            </p>
                            <p className={styles.contactInfowhatzap}>
                                <a
                                    href={setor.whatsapp}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.whatsappButton}
                                >
                                    <FaWhatsapp className={styles.whatsappIcon} />
                                    Enviar Mensagem
                                </a>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Contato;
