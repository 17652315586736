import React from 'react';
import styles from './Matriculas.module.css';
import TituloComponent from '../components/Titulo/TituloComponent';

const Matriculas = () => {
    return (
        <>
            <TituloComponent name="📚 Matrículas 2025 - Colégio Alternativo Colaço" />
            <div className={styles.container}>
                <section className={styles.section}>
                    <div className={styles.sectionTitle}>📌 Informações Gerais</div>
                    <p>🎓 Há 28 anos, construindo laços e saberes.</p>
                    <p><strong>📍 Sede:</strong> Rua Viriato Ribeiro, 724, Bela Vista</p>
                    <div className={styles.contacts}>
                        <strong>📞 Contatos:</strong>
                        <ul className={styles.listContainer}>
                            <li className={styles.listItem}>📲 Direção: (85) 9.8806-8633</li>
                            <li className={styles.listItem}>📲 Atendimento: (85) 9.3482-2415</li>
                            <li className={styles.listItem}>📲 Secretaria: (85) 9.8898-7899</li>
                            <li className={styles.listItem}>💬 Fixo/WhatsApp: (85) 3482-2415</li>
                        </ul>
                    </div>
                    <p><strong>🗓️ Início das aulas:</strong> 21/01/2025</p>
                </section>

                <section className={styles.section}>
                    <div className={styles.sectionTitle}>🗂️ Documentação de Matrícula</div>
                    <ul className={styles.listContainer}>
                        <li className={styles.listItem}>📸 1 Foto 3x4 (com nome no verso)</li>
                        <li className={styles.listItem}>📄 Xérox: cartão de vacina atualizado, certidão de nascimento, comprovante de residência, RG e CPF do responsável financeiro</li>
                        <li className={styles.listItem}>📂 1 pasta escolar; declaração da escola anterior (alunos da Educação Infantil e Fundamental)</li>
                        <li className={styles.listItem}>📑 Histórico Escolar (alunos do Ensino Fundamental)</li>
                        <li className={styles.listItem}>✍️ Contrato preenchido e assinado pelo responsável financeiro (disponível na diretoria)</li>
                        <li className={styles.listItem}>🔗 Ficha de matrícula on-line no link: <a href="https://escola.educacaoalternativa360.com.br/ficha/public/U2FsdGVkX19s28Cpr12VirjnbOvX9DQvteoZMPPyKe49oImrjPkQERXxYFCtf0yL" target="_blank" rel="noopener noreferrer">Matrícula Online</a></li>
                        <li className={styles.listItem}>📄 Resma de papel ofício (500 folhas)</li>
                    </ul>
                </section>

                <section className={styles.section}>
                    <div className={styles.sectionTitle}>⏰ Horários do Colégio</div>
                    <ul className={styles.listContainer}>
                        <li className={styles.listItem}><strong>👶 Educação Infantil:</strong> 07h às 10h45 / 13h às 16h45</li>
                        <li className={styles.listItem}><strong>📘 Ensino Fundamental:</strong> 07h às 11h / 13h às 17h</li>
                        <li className={styles.listItem}><strong>🏫 Integral:</strong> 07h às 17h (lanche + almoço + lanche)</li>
                        <li className={styles.listItem}><strong>🌞 Semi-Integral:</strong> 07h às 13h ou 11h às 17h (almoço + lanche)</li>
                    </ul>
                </section>

                <section className={styles.section}>
                    <div className={styles.sectionTitle}>💲 Mensalidades e Diárias</div>
                    <ul className={styles.listContainer}>
                        <li className={styles.listItem}><strong>📚 Escolar:</strong> R$ 4.987,00 (12x R$ 499,00 ou R$ 399,00 com desconto)</li>
                        <li className={styles.listItem}><strong>🕰️ Semi-Integral:</strong> R$ 11.419,00 (12x R$ 1.136,00 ou R$ 935,00 com desconto)</li>
                        <li className={styles.listItem}><strong>🏠 Integral:</strong> R$ 16.207,00 (12x R$ 1.634,00 ou R$ 1.334,00 com desconto)</li>
                    </ul>
                    <p>🕑 Diárias:</p>
                    <ul className={styles.listContainer}>
                        <li className={styles.listItem}>🍎 Integral: R$ 92,00 (lanche, almoço e lanche)</li>
                        <li className={styles.listItem}>🍎 Semi-Integral: R$ 65,00 (manhã ou tarde)</li>
                        <li className={styles.listItem}>🍎 Turno: R$ 45,00 (manhã ou tarde)</li>
                        <li className={styles.listItem}>⏱️ Adicional Fracionado: R$ 12,00 (hora inteira) / R$ 6,00 (meia hora)</li>
                    </ul>
                </section>

                <section className={styles.section}>
                    <div className={styles.sectionTitle}>⚽ Esportes e Recreação</div>
                    <ul className={styles.listContainer}>
                        <li className={styles.listItem}>🏅 1 Esporte: R$ 92,00</li>
                        <li className={styles.listItem}>🏅 2 Esportes: R$ 150,00</li>
                        <li className={styles.listItem}>🏅 3 Esportes: R$ 192,00</li>
                        <li className={styles.listItem}>💡 Aula Experimental: R$ 15,00</li>
                    </ul>
                </section>

                <div className={styles.contactButtonContainer}>
                    <button
                        className={styles.contactButton}
                        onClick={() => window.location.href = 'tel:5585988068633'}
                    >
                        ☎️ Entrar em contato: (85) 9.8806-8633
                    </button>
                </div>
            </div>
        </>
    );
};

export default Matriculas;
